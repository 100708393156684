import React from 'react';
import type { FC } from 'react';

import SEO from 'components/SEO';
import type { SEOProps } from 'components/SEO';

/**
 * Creates a new component that wraps received component
 * with React Helmet SEO.
 *
 * @param WrappedComponent - Component to wrap with SEO.
 * @param seoProps - Props to pass to the wrapper SEO component.
 */
const withSEO = <WrappedComponentProps extends {}>(
  WrappedComponent: FC<WrappedComponentProps>,
  seoProps: SEOProps
): FC<WrappedComponentProps> => {
  const WithSEO: FC<WrappedComponentProps> = ({
    ...wrappedComponentProps
  }: WrappedComponentProps) => (
    <>
      <SEO {...seoProps} />
      <WrappedComponent {...wrappedComponentProps} />
    </>
  );

  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  WithSEO.displayName = `withSEO(${wrappedComponentName})`;
  return WithSEO;
};

export default withSEO;

import React from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet';
import type { HelmetProps } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

// SEO component prop types
export interface SEOProps extends HelmetProps {
  description?: string;
  image?: string;
  pathname?: string;
  lang?: string;
}

/**
 * Search engine optimization component for dynamic HTML heads
 */
const SEO: FC<SEOProps> = ({
  title,
  description,
  image,
  pathname,
  lang = 'en',
  meta = [],
}: SEOProps) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            titleTemplate
            description
            image
            twitterUsername
          }
        }
      }
    `
  );

  const metaTitle = title || siteMetadata.title;
  const metaDescription = description || siteMetadata.description;
  const metaUrl = `${siteMetadata.siteUrl}${pathname || ''}`;
  const metaImage = image || siteMetadata.image;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      defaultTitle={siteMetadata.title}
      titleTemplate={siteMetadata.titleTemplate}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: metaUrl,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: 'og:site_name',
          content: siteMetadata.title,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:site',
          content: siteMetadata.twitterUsername,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:url',
          content: metaUrl,
        },
        {
          name: 'twitter:image',
          content: metaImage,
        },
        ...meta,
      ]}
    />
  );
};

export default SEO;

import React from 'react';
import type { FC } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import type { GatsbyLinkProps } from 'gatsby';

import { IconButton, Link as ChakraLink } from '@chakra-ui/core';
import type {
  IconButtonProps,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/core';

import EinsteinButton from './EinsteinButton';
import type { EinsteinButtonProps } from './EinsteinButton';

// ButtonGatsbyLink component prop types
export type ButtonGatsbyLinkProps = GatsbyLinkProps<HTMLButtonElement> &
  EinsteinButtonProps;

/**
 * Chakra-UI Button with forwarded Gatsby Link
 */
export const ButtonGatsbyLink: FC<ButtonGatsbyLinkProps> = ({
  children,
  ...rest
}: ButtonGatsbyLinkProps) => (
  <EinsteinButton as={GatsbyLink as any} {...rest}>
    {children}
  </EinsteinButton>
);

// ButtonChakraLink component prop types
export type ButtonChakraLinkProps = Pick<
  ChakraLinkProps,
  'isExternal' | 'isDisabled' | 'href'
> &
  EinsteinButtonProps;

/**
 * Chakra-UI Button with forwarded Gatsby Link
 */
export const ButtonChakraLink: FC<ButtonChakraLinkProps> = ({
  children,
  variantColor = 'orange',
  variant = 'solid',
  ...rest
}: ButtonChakraLinkProps) => (
  <EinsteinButton
    as={ChakraLink as any}
    variantColor={variantColor}
    variant={variant}
    _hover={{
      textDecor: 'none',
      bg: variant === 'solid' ? `${variantColor}.600` : `${variantColor}.50`,
    }}
    {...rest}
  >
    {children}
  </EinsteinButton>
);

// IconButtonLink component prop types
export type IconButtonGatsbyLinkProps = GatsbyLinkProps<HTMLButtonElement> &
  IconButtonProps;

/**
 * Chakra-UI IconButton with forwarded Gatsby Link
 */
export const IconButtonGatsbyLink: FC<IconButtonGatsbyLinkProps> = ({
  children,
  ...rest
}: IconButtonGatsbyLinkProps) => (
  <IconButton as={GatsbyLink as any} {...rest}>
    {children}
  </IconButton>
);

// IconButtonLink component prop types
export type IconButtonChakraLinkProps = ChakraLinkProps & IconButtonProps;

/**
 * Chakra-UI IconButton with forwarded Chakra-UI Link
 */
export const IconButtonChakraLink: FC<IconButtonChakraLinkProps> = ({
  children,
  ...rest
}: IconButtonChakraLinkProps) => (
  <IconButton as={ChakraLink as any} {...rest}>
    {children}
  </IconButton>
);

// RouterLink component prop types
export type RouterLinkProps = GatsbyLinkProps<HTMLAnchorElement> &
  ChakraLinkProps;

/**
 * Chakra-UI link with forwarded Gatsby Link
 */
export const RouterLink: FC<RouterLinkProps> = ({
  ...rest
}: RouterLinkProps) => {
  return <ChakraLink as={GatsbyLink as any} {...rest} />;
};

import React from 'react';
import type { FC } from 'react';

import { Button } from '@chakra-ui/core';
import type { ButtonProps } from '@chakra-ui/core';

// EinsteinButton component prop types
export type EinsteinButtonProps = ButtonProps;

/**
 * Custom styled Chakra-UI Button component
 */
const EinsteinButton: FC<EinsteinButtonProps> = ({
  children,
  ...rest
}: EinsteinButtonProps) => (
  <Button
    variantColor="orange"
    rounded={8}
    fontFamily="heading"
    textTransform="uppercase"
    {...rest}
  >
    {children}
  </Button>
);

export default EinsteinButton;
